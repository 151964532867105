import axiosHelper from '../helper/AxiosHelper';
import * as env from '../../env';

const serverUrl = env.httpProtocol.concat(
  env.serverHost,
  ':',
  env.serverPort,
  '/domain',
);

const paramHeaders = {
  headers: {
    Accept: 'application/json',
  },
  withCredentials: true,
};

export const getDomainAPI = async (code: string) => {
  const url = serverUrl.concat('/', code);
  return await axiosHelper.axiosGet(url, paramHeaders);
};
export const updateDomainAPI = async (
  code: string,
  name: string,
  description: string,
  jsonObject: any,
) => {
  const url = serverUrl.concat('/create');
  return await axiosHelper.axiosPost(
    url,
    { code, name, description, jsonObject },
    paramHeaders,
  );
};
export const getPairConversionAPI = async (from: string, to: string) => {
  const url = serverUrl.concat('/rate-pair-conversion/', from, '/', to);
  return await axiosHelper.axiosGet(url, paramHeaders);
};

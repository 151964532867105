import axiosHelper from '../helper/AxiosHelper';
import * as env from '../../env';

const serverUrl = env.httpProtocol.concat(
  env.serverHost,
  ':',
  env.serverPort,
  '/billing',
);

const paramHeaders = {
  headers: {
    Accept: 'application/json',
  },
  withCredentials: true,
};

export const addOrUpdateItemPlanAPI = async (jsonBody: any) => {
  const url = serverUrl + '/add-item-plan';

  const result = await axiosHelper.axiosPost(url, jsonBody, paramHeaders);
  return result;
};

export const getItemPlanAPI = async () => {
  const url = serverUrl + '/item-plan';

  const result = await axiosHelper.axiosGet(url, paramHeaders);
  return result;
};

export const addOrUpdatePlanAPI = async (jsonBody: any) => {
  const url = serverUrl + '/add-plan';

  const result = await axiosHelper.axiosPost(url, jsonBody, paramHeaders);
  return result;
};

export const inactivePlanAPI = async (jsonBody: any) => {
  const url = serverUrl + '/inactive-plan';

  const result = await axiosHelper.axiosPost(url, jsonBody, paramHeaders);
  return result;
};

export const getPlansAPI = async () => {
  const url = serverUrl + '/plan';

  const result = await axiosHelper.axiosGet(url, paramHeaders);
  return result;
};

export const subscribeAPI = async (jsonBody: any) => {
  const url = serverUrl + '/subscribe';

  const result = await axiosHelper.axiosPost(url, jsonBody, paramHeaders);
  return result;
};

export const getSubscriptionAPI = async () => {
  const url = serverUrl + '/subscription';

  const result = await axiosHelper.axiosGet(url, paramHeaders);
  return result;
};

export const validateSubscriptionAPI = async (jsonBody: any) => {
  const url = serverUrl + '/validate-subscription';

  const result = await axiosHelper.axiosPost(url, jsonBody, paramHeaders);
  return result;
};

export const adminGetOrdersAPI = async () => {
  const url = serverUrl + '/orders-get'; // orders-get
  const result = await axiosHelper.axiosGet(url, paramHeaders);
  return result;
};

export const payOrderAPI = async (idOrder: number) => {
  const url = serverUrl + '/orders-pay';

  const result = await axiosHelper.axiosPost(url, { idOrder }, paramHeaders);
  return result;
};

export const getUserOrdersAPI = async () => {
  const url = serverUrl + '/user-orders';
  const result = await axiosHelper.axiosGet(url, paramHeaders);
  return result;
};

export const getBusinessInfoCharts = async () => {
  const url = serverUrl + '/combined-charts';
  const result = await axiosHelper.axiosGet(url, paramHeaders);
  return result;
};

export const getInvoicesAPI = async () => {
  const url = serverUrl + '/invoices-get';

  const result = await axiosHelper.axiosGet(url, paramHeaders);
  return result;
};

export const deleteOrderAPI = async (idOrder: number) => {
  const url = serverUrl + '/delete-order';

  const result = await axiosHelper.axiosPost(
    url,
    { id_object: idOrder },
    paramHeaders,
  );
  return result;
};

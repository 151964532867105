import { categoriesMap } from '../hooks/constant';
import { IProductsFilter } from '../interfaces/state-interfaces';
import axiosHelper from './AxiosHelper';

const paramHeaders = {
  headers: {
    Accept: 'application/json',
  },
};
const page = 1;
const perPage = 100;

const categorizeTemplate = (name: string, description: string, topics = []) => {
  const text = `${name} ${description} ${topics.join(' ')}`.toLowerCase();
  const matchedCategories = Object.entries(categoriesMap)
    .filter(([_, keywords]) => keywords.some(keyword => text.includes(keyword)))
    .map(([category]) => category);
  return matchedCategories.length > 0 ? matchedCategories : ['Other'];
};

const mapRepositoryData = (repo: any, platform: string) => {
  const commonData = {
    id: repo.name.toLowerCase().replace(/\s+/g, '-'),
    name: repo.name,
    categories: categorizeTemplate(repo.name, repo.description || '', []),
    description: repo.description || 'No description available',
  };

  switch (platform) {
    case 'github':
      return {
        ...commonData,
        link: repo.html_url,
        image: repo.owner.avatar_url,
      };
    case 'gitlab':
      return {
        ...commonData,
        link: repo.web_url,
        image: repo.avatar_url || '',
      };
    case 'bitbucket':
      return {
        ...commonData,
        link: repo.links.html.href,
        image: repo.links.avatar?.href || '',
      };
    default:
      throw new Error(`Unsupported platform: ${platform}`);
  }
};

async function fetchGitHubTemplates(page = 1, perPage = 10) {
  try {
    const url = `https://api.github.com/search/repositories?q=template+in:name+OR+boilerplate+in:name&sort=stars&order=desc&page=${page}&per_page=${perPage}`;
    const response = await axiosHelper.axiosGet(url, paramHeaders);
    const data = response.data.items;
   // console.log('fetchGitHubTemplates response: ', data);

    return data.map(repo => mapRepositoryData(repo, 'github'));
  } catch (error) {
    console.error('Error fetching GitHub templates:', error);
    return [];
  }
}

async function fetchGitLabTemplates(page = 1, perPage = 10) {
  try {
    const url = `https://gitlab.com/api/v4/projects?search=template&page=${page}&per_page=${perPage}`;
    const response = await axiosHelper.axiosGet(url, paramHeaders);
    const data = response.data;
   // console.log('fetchGitLabTemplates response: ', data);
    return data.map(repo => mapRepositoryData(repo, 'gitlab'));
  } catch (error) {
    console.error('Error fetching GitLab templates:', error);
    return [];
  }
}

async function fetchBitbucketTemplates(page = 1, perPage = 10) {
  try {
    const url = `https://api.bitbucket.org/2.0/repositories?pagelen=${perPage}&page=${page}`;
    const response = await axiosHelper.axiosGet(url, paramHeaders);
    const data = response.data.values;
    //console.log('fetchBitbucketTemplates response: ', data);
    const filteredRepos = data.filter(repo =>
      repo.name.toLowerCase().includes('template') ||
      (repo.description && repo.description.toLowerCase().includes('template')) ||
      repo.name.toLowerCase().includes('boilerplate')
    );
    return filteredRepos.map(repo => mapRepositoryData(repo, 'bitbucket'));
  } catch (error) {
    console.error('Error fetching Bitbucket templates:', error);
    return [];
  }
}

/**
 * Filters repositories based on search keywords and categories.
 * @param {IProductsFilter} filter - The filter object containing search and categories.
 * @param {Array} repositories - The list of repositories to filter.
 * @returns {Array} - The filtered list of repositories.
 */
const filterRepositories = (filter: IProductsFilter, repositories) => {
  const { search, categories } = filter;
  console.log('filter: ', filter);
  if(filter.search === '' && filter.categories.length ===0){
    console.log('return repo');
    return repositories;
  }
  return repositories.filter(repo => {
    // Filter by search keyword
    const matchesSearch = search
      ? repo.name.toLowerCase().includes(search.toLowerCase()) ||
        repo.description.toLowerCase().includes(search.toLowerCase())
      : true;

    // Filter by categories
    const matchesCategories = categories.includes('all') // Include all if "All" is selected
      ? true
      : categories.some(category => repo.categories.includes(category));

    return matchesSearch && matchesCategories;
  });
};

export async function fetchAllTemplates(filter: IProductsFilter) {
  const [github, gitlab, bitbucket] = await Promise.all([
    fetchGitHubTemplates(page, perPage * 0.5), // only 50% of result
    fetchGitLabTemplates(page, perPage * 0.25), // only 25% of result
    fetchBitbucketTemplates(page, perPage * 0.25), // only 25% of result
  ]);

  let allTemplates = [...github, ...gitlab, ...bitbucket];

  // Filter out repositories without an image
 // allTemplates = allTemplates.filter(template => template.image);
  console.log('allTemplates: ', allTemplates);

  // Apply search and category filters
  allTemplates = filterRepositories(filter, allTemplates);
//console.log('allTemplates: ', allTemplates);
  return allTemplates;
}
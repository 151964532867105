import axiosHelper from '../helper/AxiosHelper';
import * as env from '../../env';
import { IOrganization } from '../interfaces/interfaces';

const serverUrl = env.httpProtocol.concat(
    env.serverHost,
    ':',
    env.serverPort,
    '/git-provider',
);

const paramHeaders = {
    headers: {
        Accept: 'application/json',
    },
    withCredentials: true,
};

const paramHeadersFormData = {
    headers: {
        Accept: 'application/json',
        'Content-type': 'multipart/form-data',
    },
    withCredentials: true,
};

export const getOrganizationsProviderAPI = async (provider: string) => {
    const url = serverUrl.concat('/organizations/', provider);
    return await axiosHelper.axiosGet(url, paramHeaders);
};




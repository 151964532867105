import axiosHelper from '../helper/AxiosHelper';
import * as env from '../../env';
import { IContextRepo } from '../interfaces/interfaces';

const serverUrl = env.httpProtocol.concat(
  env.serverHost,
  ':',
  env.serverPort,
  '/no-auth',
);

const paramHeaders = {
  headers: {
    Accept: 'application/json',
  },
  // withCredentials: true,
};

export const noAuthSubscribeTrialAPI = async (email: string) => {
  const url = serverUrl + '/subscribe/trial';
  return await axiosHelper.axiosPost(url, { email }, paramHeaders);
};

export const noAuthAddContextRepoAPI = async (contextRepo: IContextRepo) => {
  const url = serverUrl + '/add-context-repo';

  const result = await axiosHelper.axiosPost(url, contextRepo, paramHeaders);
  return result;
};

export const noAuthSubscribeToServiceAPI = async (jsonBody: any) => {
  const url = serverUrl + '/subscribe';

  const result = await axiosHelper.axiosPost(url, jsonBody, paramHeaders);
  return result;
};

export const noAuthGetProductsAPI = async () => {
  const url = serverUrl + '/products';
  return await axiosHelper.axiosGet(url, paramHeaders);
};

import axiosHelper from '../helper/AxiosHelper';
import * as env from '../../env';
import { ISubscriptionParam } from '../interfaces/interfaces';

const serverUrl = env.httpProtocol.concat(
  env.serverHost,
  ':',
  env.serverPort,
  '/service',
);

const paramHeaders = {
  headers: {
    Accept: 'application/json',
  },
  withCredentials: true,
};

export const subscribeAPI = async (data: ISubscriptionParam) => {
  const url = serverUrl + '/subscribe';
  return await axiosHelper.axiosPost(url, data, paramHeaders);
};

export const resetServiceAPI = async (
  serviceName: string,
  idObject: number,
) => {
  const url = serverUrl + '/reset-service';
  return await axiosHelper.axiosPost(
    url,
    { serviceName, idObject },
    paramHeaders,
  );
};

export const getProductsAPI = async () => {
  const url = serverUrl + '/';
  return await axiosHelper.axiosGet(url, paramHeaders);
};
export const exposeDbAPI = async (serviceName: string, idObject: number) => {
  const url = serverUrl + '/expose-db';
  return await axiosHelper.axiosPost(
    url,
    { serviceName, idObject },
    paramHeaders,
  );
};

export const changeDomainAPI = async (
  serviceName: string,
  idObject: number,
  domainName: string,
) => {
  const url = serverUrl + '/change-domain';
  return await axiosHelper.axiosPost(
    url,
    { serviceName, idObject, domainName },
    paramHeaders,
  );
};

export const backupNamespaceAPI = async () => {
  const url = serverUrl.concat('/backup-namespace');
  return await axiosHelper.axiosPost(url, {}, paramHeaders);
};

export const restoreNamespaceAPI = async (
  backupName: string,
  namespace?: string,
) => {
  const url = serverUrl.concat('/restore-namespace');
  return await axiosHelper.axiosPost(
    url,
    { backupName, namespace },
    paramHeaders,
  );
};

export const subscribeByAdminAPI = async (
  code: string,
  email: string,
  namespace?: string,
) => {
  const url = serverUrl + '/subscribe-by-admin';
  return await axiosHelper.axiosPost(
    url,
    { code: code, email: email, namespace: namespace },
    paramHeaders,
  );
};

export const validateDomainNameAPI = async (domainName: string) => {
  const url = serverUrl + '/validate-domain';
  return await axiosHelper.axiosPost(
    url,
    { domainName: domainName },
    paramHeaders,
  );
};

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { setMessage } from './message';
import { deleteServiceAPI, getNamespaceInfoAPI } from '../services/namespace';
import { ServiceStateProps } from '../interfaces/state-interfaces';
import {
  backupNamespaceAPI,
  changeDomainAPI,
  exposeDbAPI,
  resetServiceAPI,
  subscribeAPI,
} from '../services/service';
import {
  IBackup,
  ISubscriptionParam,
} from '../interfaces/interfaces';
import i18next from 'i18next';
import { isValidResult } from '../helper/UtilHelper';
import { listDomainsAPI, searchBusinessEmailOrdersAPI, searchEnterpriseEmailOrdersAPI } from '../services/resellerclub';
import { IApplication } from '../interfaces/argocd-interface';
import {
  ISearchEmailRequest,
  IListDomainsResponse,
  IListEmailResponse,
} from '../interfaces/reseller-club-interfaces';

interface IServiceData {
  owner: number;
  namespace: string;
}
export const getServices = createAsyncThunk(
  'get/services',
  async (data: any, thunkAPI) => {
    try {
      let response;
      if (data.owner !== 0 && data.namespace !== '') {
        response = await getNamespaceInfoAPI();
      }
      let services: IApplication[] = [];
      let backup: IBackup | undefined;
      if (isValidResult(response)) {
        services = response.data;
        // backup = response.data.json.backup; // Refactor TODO create get backup
      }
      return {
        services: services,
        // backup: backup,
      };
    } catch (error: any) {
      const message = JSON.stringify(error);
      thunkAPI.dispatch(setMessage({ message: message, severity: 'error' }));
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);

export const subscribeToService = createAsyncThunk(
  'subscribe/services',
  async (data: ISubscriptionParam, thunkAPI) => {
    try {
      const response = await subscribeAPI(data);
      let services = [];
      if (response && response.data && response.status === 200) {
        const message = i18next.t('success', { ns: 'dashboard-index' });

        thunkAPI.dispatch(
          setMessage({
            message: message,
            severity: 'success',
          }),
        );
        services = response.data;
      } else {
        thunkAPI.dispatch(
          setMessage({
            message: JSON.stringify(response.data),
            severity: 'error',
          }),
        );
      }
      return {
        services: services,
      };
    } catch (error: any) {
      const message = JSON.stringify(error);
      thunkAPI.dispatch(setMessage({ message: message, severity: 'error' }));
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);

export const deleteService = createAsyncThunk(
  'delete/services',
  async (applicationName: string, thunkAPI) => {
    try {
      const deleteResponse = await deleteServiceAPI(applicationName);
      const response = await getNamespaceInfoAPI();
      let services = [];
      if (response && response.data && response.status === 200) {
        services = response.data;
      } else {
        thunkAPI.dispatch(
          setMessage({
            message: JSON.stringify(response.data),
            severity: 'error',
          }),
        );
        // trow app error return thunkAPI.rejectWithValue(response.data);
      }
      return {
        services: services,
      };
    } catch (error: any) {
      const message = JSON.stringify(error);
      thunkAPI.dispatch(setMessage({ message: message, severity: 'error' }));
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);

export const exposeDb = createAsyncThunk(
  'exposeDb/services',
  async (data: any, thunkAPI) => {
    try {
      const response = await exposeDbAPI(data.serviceName, data.idObject);
      let services = [];
      if (response && response.data && response.status === 200) {
        services = response.data;
      } else {
        thunkAPI.dispatch(
          setMessage({
            message: JSON.stringify(response.data),
            severity: 'error',
          }),
        );
        // trow app error return thunkAPI.rejectWithValue(response.data);
      }
      return {
        services: services,
      };
    } catch (error: any) {
      const message = JSON.stringify(error);
      thunkAPI.dispatch(setMessage({ message: message, severity: 'error' }));
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);

export const changeApplicationDomain = createAsyncThunk(
  'changeDomain/services',
  async (data: any, thunkAPI) => {
    try {
      const response = await changeDomainAPI(
        data.serviceName,
        data.idObject,
        data.domainName,
      );
      let services = [];
      if (response && response.data && response.status === 200) {
        services = response.data;
      } else {
        thunkAPI.dispatch(
          setMessage({
            message: JSON.stringify(response.data),
            severity: 'error',
          }),
        );
        // trow app error  return thunkAPI.rejectWithValue(response.data);
      }
      return {
        services: services,
      };
    } catch (error: any) {
      const message = JSON.stringify(error);
      thunkAPI.dispatch(setMessage({ message: message, severity: 'error' }));
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);

export const resetService = createAsyncThunk(
  'resetService/services',
  async (data: any, thunkAPI) => {
    try {
      const response = await resetServiceAPI(data.serviceName, data.idObject);
      let services = [];
      if (response && response.data && response.status === 200) {
        services = response.data;
      } else {
        thunkAPI.dispatch(
          setMessage({
            message: JSON.stringify(response.data),
            severity: 'error',
          }),
        );
        // trow app error return thunkAPI.rejectWithValue(response.data);
      }
      return {
        services: services,
      };
    } catch (error: any) {
      const message = JSON.stringify(error);
      thunkAPI.dispatch(setMessage({ message: message, severity: 'error' }));
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);

export const backupNamespace = createAsyncThunk(
  'backup/namespace',
  async (data: any, thunkAPI) => {
    try {
      const response = await backupNamespaceAPI();
      let backup: IBackup = {
        name: '',
        lastBackup: 0,
      };
      if (response && response.data && response.status === 200) {
        backup = response.data.backup;
      } else {
        thunkAPI.dispatch(
          setMessage({
            message: JSON.stringify(response.data),
            severity: 'error',
          }),
        );
      }
      return {
        backup: backup,
      };
    } catch (error: any) {
      const message = JSON.stringify(error);
      thunkAPI.dispatch(setMessage({ message: message, severity: 'error' }));
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);

export const getDomains = createAsyncThunk(
  'get/domains',
  async (data: any, thunkAPI) => {
    try {
      let response;
      response = await listDomainsAPI();
      let domains: IListDomainsResponse = {};
      if (isValidResult(response)) {
        domains = response.data;
      }
      return {
        domains,
      };
    } catch (error: any) {
      const message = JSON.stringify(error);
      thunkAPI.dispatch(setMessage({ message: message, severity: 'error' }));
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);

export const getEnterpriseEmails = createAsyncThunk(
  'get/enterpriseEmails',
  async (request: ISearchEmailRequest, thunkAPI) => {
    try {
      let response;
      response = await searchEnterpriseEmailOrdersAPI(request);
      let enterpriseEmails: IListEmailResponse = {};
      if (isValidResult(response)) {
        enterpriseEmails = response.data;
      }
      return {
        enterpriseEmails,
      };
    } catch (error: any) {
      const message = JSON.stringify(error);
      thunkAPI.dispatch(setMessage({ message: message, severity: 'error' }));
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);

export const getBusinessEmails = createAsyncThunk(
  'get/businessEmails',
  async (request: ISearchEmailRequest, thunkAPI) => {
    try {
      let response;
      response = await searchBusinessEmailOrdersAPI(request);
      let businessEmails: IListEmailResponse = {};
      if (isValidResult(response)) {
        businessEmails = response.data;
      }
      return {
        businessEmails,
      };
    } catch (error: any) {
      const message = JSON.stringify(error);
      thunkAPI.dispatch(setMessage({ message: message, severity: 'error' }));
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);

const initialState: ServiceStateProps = {
  services: [],
  backup: {
    name: '',
    lastBackup: 0,
  },
  enterpriseEmails: {},
  domains: {},
  businessEmails: {}
};
const serviceSlice = createSlice({
  name: 'service',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getServices.fulfilled, (state, action) => {
      state.services = action.payload.services;
      // state.backup = action.payload.backup;
    });
    builder.addCase(subscribeToService.fulfilled, (state, action) => {
      state.services = action.payload.services;
    });
    builder.addCase(deleteService.fulfilled, (state, action) => {
      state.services = action.payload.services;
    });
    builder.addCase(exposeDb.fulfilled, (state, action) => {
      state.services = action.payload.services;
    });
    builder.addCase(changeApplicationDomain.fulfilled, (state, action) => {
      state.services = action.payload.services;
    });
    builder.addCase(resetService.fulfilled, (state, action) => {
      state.services = action.payload.services;
    });
    builder.addCase(backupNamespace.fulfilled, (state, action) => {
      state.backup = action.payload.backup;
    });
    builder.addCase(getDomains.fulfilled, (state, action) => {
      state.domains = action.payload.domains;
    });
    builder.addCase(getEnterpriseEmails.fulfilled, (state, action) => {
      state.enterpriseEmails = action.payload.enterpriseEmails;
    });
    builder.addCase(getBusinessEmails.fulfilled, (state, action) => {
      state.businessEmails = action.payload.businessEmails;
    });
  },
});

const { reducer } = serviceSlice;
export default reducer;

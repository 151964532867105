// theme constant
export const gridSpacing = 3;
export const drawerWidth = 260;
export const appDrawerWidth = 320;

export const e2eAccount = {
  username: 'angelocorreia27@hotmail.com',
  password: 'password',
};
export default {
  DB1_PUBLIC_HOST: 'db1.nebuacloud.com',
  CORE: {
    USERS: 'USERS',
    USER_SETTING: 'USERS_SETTING',
    API_KEY: 'API KEY',
    ORDER_PAY: 'OrderPay',
    ORDER: 'ORDER',
    SUBSCRIPTIONS: 'SUBSCRIPTIONS',
    STATUS_ACTIVE: 'A',
    STATUS_INACTIVE: 'I',
    EXECUTION_DATA: 'EXECUTION_DATA',
    NAMESPACE: 'USERS_NAMESPACE',
    ADMIN_EMAIL: 'commercial@nebuacloud.com',
    WORKSPACE: 'USER_WORKSPACE',
    LOG: 'LOG',
    DOMAIN: 'DOMAIN',
    DEFAULT_PAYMENT_CURRENCY: 'EUR',
    DNS: 'DNS',
  },
  /**Open EDX */
  OpenEDX: {
    ROOT_CODE: 'OpenEDX',
  },
  BILLING: {
    PRICE_PLAN: 'PRICE_PLAN',
    ITEM_PLAN: 'ITEM_PLAN',
    SUBSCRIPTIONS: 'SUBSCRIPTIONS',
  },
  Odoo: {
    ROOT_CODE: 'Odoo',
  },
  REAL_TIME_DB: {
    ROOT_CODE: 'REAL_TIME_DB',
  },
  DB: {
    PGSQL_CODE: 'PostgreSQL',
    MYSQL_CODE: 'MySQL',
    MONGODB_CODE: 'MongoDB',
    NEO4J_CODE: 'Neo4jDB',
    MARIADB: 'MariaDB',
  },
  PHPMyAdmin: {
    ROOT_CODE: 'PHPMyAdmin',
  },
  Invoice: {
    ROOT_CODE: 'INVOICE',
  },
  Order: {
    ROOT_CODE: 'ORDER',
  },
  IGRP: {
    ROOT_CODE: 'IGRP',
  },
  CUSTOM: {
    ROOT_CODE: 'CUSTOM',
  },
  WORDPRESS: {
    ROOT_CODE: 'WORDPRESS',
  },
  MOODLE: {
    ROOT_CODE: 'MOODLE',
  },
  FIREFLYIII: {
    ROOT_CODE: 'FIREFLYIII',
  },
  OPENWEBUI: {
    ROOT_CODE: 'OpenWebui',
  },
  OLLAMA: {
    ROOT_CODE: 'Ollama',
  },
  KAFKA: {
    ROOT_CODE: 'KAFKA',
  },
  OPENCART: {
    ROOT_CODE: 'OPENCART',
  },
  BACKUP: {
    ROOT_CODE: 'BACKUP',
  },
  RESTORE: {
    ROOT_CODE: 'RESTORE',
  },
  JOOMLA: {
    ROOT_CODE: 'JOOMLA',
  },
  NEXTCLOUD: {
    ROOT_CODE: 'NEXTCLOUD',
  },
  JENKINS: {
    ROOT_CODE: 'JENKINS',
  },
  OPENVPN: {
    ROOT_CODE: 'OPENVPN',
  },
  VERIFONE: {
    SS_PRODUCT_ID: '45334610',
    SS_PRODUCT_CODE: 'SS',
    ES_PRODUCT_ID: '45334920',
    ES_PRODUCT_CODE: 'ES',
  },
};

export const categoriesMap = {
  Framework: ['react', 'angular', 'vue', 'svelte', 'framework'],
  Backend: ['node', 'express', 'nestjs', 'django', 'flask', 'spring', 'laravel', 'api'],
  AI: ['ai', 'machine learning', 'ml', 'deep learning', 'neural', 'open-webui', 'ollama'],
  DevOps: ['docker', 'kubernetes', 'terraform', 'ansible', 'cicd'],
  CMS: ['wordpress', 'strapi', 'keystone', 'contentful', 'headless'],
  'E-Commerce': ['shopify', 'magento', 'woocommerce', 'commerce', 'store'],
  'Static Site': ['hugo', 'jekyll', 'gatsby', 'astro', 'opencart', 'joomla'],
  'Game Development': ['unity', 'godot', 'unreal', 'gamedev', 'nextcloud', 'discourse', 'moodle'],
  ERP: ['odoo' ],
  Database: ['postgresql', 'mongodb', 'mariadb', 'mysql']
};
import axiosHelper from '../helper/AxiosHelper';
import * as env from '../../env';
import { ICustomer, IEnterpriseEmail } from '../interfaces/interfaces';
import { IDomain, ISearchEmailRequest } from '../interfaces/reseller-club-interfaces';

const serverUrl = env.httpProtocol.concat(
  env.serverHost,
  ':',
  env.serverPort,
  '/resellerclub',
);

const paramHeaders = {
  headers: {
    Accept: 'application/json',
  },
  withCredentials: true,
};

const paramHeadersFormData = {
  headers: {
    Accept: 'application/json',
    'Content-type': 'multipart/form-data',
  },
  withCredentials: true,
};

export const getCustomerAPI = async (username: string) => {
  const url = `${serverUrl}/customer/${username}`;
  return await axiosHelper.axiosGet(url, paramHeaders);
};

export const addCustomerAPI = async (customer: ICustomer) => {
  const url = `${serverUrl}/customer`;
  return await axiosHelper.axiosPost(url, customer, paramHeaders);
};

export const generateLoginTokenAPI = async (credentials: any) => {
  const url = `${serverUrl}/generate-login-token`;
  return await axiosHelper.axiosPost(url, credentials, paramHeaders);
};

export const getCustomerControlPanelURLAPI = async (token: string) => {
  const url = `${serverUrl}/customer-control-panel-url/${token}`;
  return await axiosHelper.axiosGet(url, paramHeaders);
};

export const listDomainsAPI = async () => {
  const url = `${serverUrl}/domains`;
  return await axiosHelper.axiosGet(url, paramHeaders);
};

export const deleteDomainAPI = async (orderId: number) => {
  const url = `${serverUrl}/domain/${orderId}`;
  return await axiosHelper.axiosDelete(url, paramHeaders);
};

export const renewDomainAPI = async (domain: IDomain) => {
  const url = `${serverUrl}/domain/renew`;
  return await axiosHelper.axiosPost(url, domain, paramHeaders);
};

export const registerDomainAPI = async (domain: IDomain) => {
  const url = `${serverUrl}/domain/register`;
  return await axiosHelper.axiosPost(url, domain, paramHeaders);
};

export const addEnterpriseEmailAPI = async (emailData: IEnterpriseEmail) => {
  const url = `${serverUrl}/enterprise-email`;
  return await axiosHelper.axiosPost(url, emailData, paramHeaders);
};

export const renewEnterpriseEmailAPI = async (emailData: IEnterpriseEmail) => {
  const url = `${serverUrl}/enterprise-email/renew`;
  return await axiosHelper.axiosPost(url, emailData, paramHeaders);
};

export const addEnterpriseEmailAccountsAPI = async (
  emailData: IEnterpriseEmail,
) => {
  const url = `${serverUrl}/enterprise-email/accounts`;
  return await axiosHelper.axiosPost(url, emailData, paramHeaders);
};

export const deleteEnterpriseEmailAccountsAPI = async (data: any) => {
  const url = `${serverUrl}/enterprise-email/accounts`;
  return await axiosHelper.axiosDelete(url, paramHeaders);
};

export const suspendOrderAPI = async (data: any) => {
  const url = `${serverUrl}/order/suspend`;
  return await axiosHelper.axiosPost(url, data, paramHeaders);
};

export const deleteEnterpriseOrderAPI = async (orderId: any) => {
  const url = `${serverUrl}/enterprise-order/${orderId}`;
  return await axiosHelper.axiosDelete(url, paramHeaders);
};

export const getEnterpriseEmailOrderDetailsAPI = async (orderId: any) => {
  const url = `${serverUrl}/enterprise-email-order/${orderId}`;
  return await axiosHelper.axiosGet(url, paramHeaders);
};

export const getEnterpriseEmailOrderIdAPI = async (domainName: any) => {
  const url = `${serverUrl}/enterprise-email-order-id/${domainName}`;
  return await axiosHelper.axiosGet(url, paramHeaders);
};

export const searchEnterpriseEmailOrdersAPI = async (request: ISearchEmailRequest) => {
  const url = `${serverUrl}/enterprise-email-orders`;
  return await axiosHelper.axiosPost(url, request, paramHeaders);
};

export const getEnterpriseEmailDnsRecordsAPI = async (orderId: any) => {
  const url = `${serverUrl}/enterprise-email-dns/${orderId}`;
  return await axiosHelper.axiosGet(url, paramHeaders);
};

export const updateDomainContactAPI = async (data: any) => {
  const url = `${serverUrl}/domain/update-contact`;
  return await axiosHelper.axiosPost(url, data, paramHeaders);
};

export const setDomainPrivacyProtectionAPI = async (data: any) => {
  const url = `${serverUrl}/domain/set-privacy-protection`;
  return await axiosHelper.axiosPost(url, data, paramHeaders);
};

export const transferDomainAPI = async (data: any) => {
  const url = `${serverUrl}/domain/transfer`;
  return await axiosHelper.axiosPost(url, data, paramHeaders);
};

export const checkDomainAvailabilityAPI = async (domainName: any) => {
  const url = `${serverUrl}/domain/check-availability/${domainName}`;
  return await axiosHelper.axiosGet(url, paramHeaders);
};

export const getDomainDetailsAPI = async (domainName: any) => {
  const url = `${serverUrl}/domain-details/${domainName}`;
  return await axiosHelper.axiosGet(url, paramHeaders);
};

export const setDomainAutoRenewAPI = async (data: any) => {
  const url = `${serverUrl}/domain/set-auto-renew`;
  return await axiosHelper.axiosPost(url, data, paramHeaders);
};

export const getDomainPricingAPI = async (tld: any, action: any) => {
  const url = `${serverUrl}/domain-pricing/${tld}/${action}`;
  return await axiosHelper.axiosGet(url, paramHeaders);
};

export const getDomainSuggestionsAPI = async (data: any) => {
  const url = `${serverUrl}/domain-suggestions`;
  return await axiosHelper.axiosPost(url, data, paramHeaders);
};

export const getDomainTransferStatusAPI = async (orderId: any) => {
  const url = `${serverUrl}/domain-transfer-status/${orderId}`;
  return await axiosHelper.axiosGet(url, paramHeaders);
};

export const cancelDomainTransferAPI = async (data: any) => {
  const url = `${serverUrl}/domain-transfer/cancel`;
  return await axiosHelper.axiosPost(url, data, paramHeaders);
};

export const getDomainDnsRecordsAPI = async (domainName: any) => {
  const url = `${serverUrl}/domain-dns/${domainName}`;
  return await axiosHelper.axiosGet(url, paramHeaders);
};

export const modifyDomainDnsRecordsAPI = async (data: any) => {
  const url = `${serverUrl}/domain-dns/modify`;
  return await axiosHelper.axiosPost(url, data, paramHeaders);
};

export const setDomainTheftProtectionAPI = async (data: any) => {
  const url = `${serverUrl}/domain/set-theft-protection`;
  return await axiosHelper.axiosPost(url, data, paramHeaders);
};

export const getDomainLockStatusAPI = async (domainName: any) => {
  const url = `${serverUrl}/domain-lock-status/${domainName}`;
  return await axiosHelper.axiosGet(url, paramHeaders);
};

export const setDomainLockAPI = async (data: any) => {
  const url = `${serverUrl}/domain/set-lock`;
  return await axiosHelper.axiosPost(url, data, paramHeaders);
};

export const getDomainExpiryDateAPI = async (domainName: any) => {
  const url = `${serverUrl}/domain-expiry-date/${domainName}`;
  return await axiosHelper.axiosGet(url, paramHeaders);
};

export const getDomainRegistrationDateAPI = async (domainName: any) => {
  const url = `${serverUrl}/domain-registration-date/${domainName}`;
  return await axiosHelper.axiosGet(url, paramHeaders);
};

export const getDomainNameserversAPI = async (domainName: any) => {
  const url = `${serverUrl}/domain-nameservers/${domainName}`;
  return await axiosHelper.axiosGet(url, paramHeaders);
};

export const modifyDomainNameserversAPI = async (data: any) => {
  const url = `${serverUrl}/domain-nameservers/modify`;
  return await axiosHelper.axiosPost(url, data, paramHeaders);
};

export const getDomainChildNameserversAPI = async (domainName: any) => {
  const url = `${serverUrl}/domain-child-nameservers/${domainName}`;
  return await axiosHelper.axiosGet(url, paramHeaders);
};

export const addDomainChildNameserverAPI = async (data: any) => {
  const url = `${serverUrl}/domain-child-nameserver`;
  return await axiosHelper.axiosPost(url, data, paramHeaders);
};

export const deleteDomainChildNameserverAPI = async (data: any) => {
  const url = `${serverUrl}/domain-child-nameserver`;
  return await axiosHelper.axiosDelete(url, paramHeaders);
};

export const modifyDomainChildNameserverAPI = async (data: any) => {
  const url = `${serverUrl}/domain-child-nameserver`;
  return await axiosHelper.axiosPut(url, data, paramHeaders);
};

export const getDomainDnssecDetailsAPI = async (domainName: any) => {
  const url = `${serverUrl}/domain-dnssec/${domainName}`;
  return await axiosHelper.axiosGet(url, paramHeaders);
};

export const setDomainDnssecAPI = async (data: any) => {
  const url = `${serverUrl}/domain-dnssec/set`;
  return await axiosHelper.axiosPost(url, data, paramHeaders);
};

export const addDomainDnssecRecordAPI = async (data: any) => {
  const url = `${serverUrl}/domain-dnssec/add-record`;
  return await axiosHelper.axiosPost(url, data, paramHeaders);
};

export const deleteDomainDnssecRecordAPI = async (data: any) => {
  const url = `${serverUrl}/domain-dnssec/delete-record`;
  return await axiosHelper.axiosDelete(url, paramHeaders);
};

export const getDomainWhoisDetailsAPI = async (domainName: any) => {
  const url = `${serverUrl}/domain-whois/${domainName}`;
  return await axiosHelper.axiosGet(url, paramHeaders);
};

export const modifyDomainWhoisContactAPI = async (data: any) => {
  const url = `${serverUrl}/domain-whois/modify-contact`;
  return await axiosHelper.axiosPost(url, data, paramHeaders);
};

// Rotas para transferência de domínio
export const getDomainTransferEligibilityAPI = async (domainName: any) => {
  const url = `${serverUrl}/domain-transfer-eligibility/${domainName}`;
  return await axiosHelper.axiosGet(url, paramHeaders);
};

export const getDomainTransferAuthCodeAPI = async (domainName: any) => {
  const url = `${serverUrl}/domain-transfer-auth-code/${domainName}`;
  return await axiosHelper.axiosGet(url, paramHeaders);
};

export const getDomainTransferLockStatusAPI = async (domainName: any) => {
  const url = `${serverUrl}/domain-transfer-lock-status/${domainName}`;
  return await axiosHelper.axiosGet(url, paramHeaders);
};

export const setDomainTransferLockAPI = async (data: any) => {
  const url = `${serverUrl}/domain-transfer/set-lock`;
  return await axiosHelper.axiosPost(url, data, paramHeaders);
};

export const getDomainTransferRejectionReasonsAPI = async (orderId: any) => {
  const url = `${serverUrl}/domain-transfer-rejection-reasons/${orderId}`;
  return await axiosHelper.axiosGet(url, paramHeaders);
};

export const resendDomainTransferApprovalEmailAPI = async (data: any) => {
  const url = `${serverUrl}/domain-transfer/resend-approval-email`;
  return await axiosHelper.axiosPost(url, data, paramHeaders);
};

export const getDomainTransferDetailsAPI = async (orderId: any) => {
  const url = `${serverUrl}/domain-transfer-details/${orderId}`;
  return await axiosHelper.axiosGet(url, paramHeaders);
};

export const getDomainTransferHistoryAPI = async (domainName: any) => {
  const url = `${serverUrl}/domain-transfer-history/${domainName}`;
  return await axiosHelper.axiosGet(url, paramHeaders);
};

export const getDomainTransferPricingAPI = async (tld: any) => {
  const url = `${serverUrl}/domain-transfer-pricing/${tld}`;
  return await axiosHelper.axiosGet(url, paramHeaders);
};

export const getDomainTransferAgreementAPI = async (domainName: any) => {
  const url = `${serverUrl}/domain-transfer-agreement/${domainName}`;
  return await axiosHelper.axiosGet(url, paramHeaders);
};

export const getDomainTransferDisputeDetailsAPI = async (orderId: any) => {
  const url = `${serverUrl}/domain-transfer-dispute-details/${orderId}`;
  return await axiosHelper.axiosGet(url, paramHeaders);
};

export const getDomainTransferDisputeResolutionAPI = async (orderId: any) => {
  const url = `${serverUrl}/domain-transfer-dispute-resolution/${orderId}`;
  return await axiosHelper.axiosGet(url, paramHeaders);
};

export const getDomainTransferDisputeStatusAPI = async (orderId: any) => {
  const url = `${serverUrl}/domain-transfer-dispute-status/${orderId}`;
  return await axiosHelper.axiosGet(url, paramHeaders);
};

export const getDomainTransferDisputeHistoryAPI = async (orderId: any) => {
  const url = `${serverUrl}/domain-transfer-dispute-history/${orderId}`;
  return await axiosHelper.axiosGet(url, paramHeaders);
};

export const getDomainTransferDisputeResolutionOptionsAPI = async (
  orderId: any,
) => {
  const url = `${serverUrl}/domain-transfer-dispute-resolution-options/${orderId}`;
  return await axiosHelper.axiosGet(url, paramHeaders);
};

export const getDomainTransferDisputeResolutionTimelineAPI = async (
  orderId: any,
) => {
  const url = `${serverUrl}/domain-transfer-dispute-resolution-timeline/${orderId}`;
  return await axiosHelper.axiosGet(url, paramHeaders);
};

export const getDomainTransferDisputeResolutionDetailsAPI = async (
  orderId: any,
) => {
  const url = `${serverUrl}/domain-transfer-dispute-resolution-details/${orderId}`;
  return await axiosHelper.axiosGet(url, paramHeaders);
};

export const getDomainTransferDisputeResolutionStatusAPI = async (
  orderId: any,
) => {
  const url = `${serverUrl}/domain-transfer-dispute-resolution-status/${orderId}`;
  return await axiosHelper.axiosGet(url, paramHeaders);
};

export const getDomainTransferDisputeResolutionHistoryAPI = async (
  orderId: any,
) => {
  const url = `${serverUrl}/domain-transfer-dispute-resolution-history/${orderId}`;
  return await axiosHelper.axiosGet(url, paramHeaders);
};
// Business Email
export const searchBusinessEmailOrdersAPI = async (request: ISearchEmailRequest) => {
  const url = `${serverUrl}/business-email`;
  return await axiosHelper.axiosPost(url, request, paramHeaders);
};

// Add Business Email Account
export const addBusinessEmailAccountAPI = async (
  orderId: string,
  email: string,
  password: string,
) => {
  const url = `${serverUrl}/business-email/${orderId}/add-account`;
  const data = { email, password };
  return await axiosHelper.axiosPost(url, data, paramHeaders);
};

// Delete Business Email Account
export const deleteBusinessEmailAccountAPI = async (
  orderId: string,
  email: string,
) => {
  const url = `${serverUrl}/business-email/${orderId}/delete-account`;
  const data = { email };
  return await axiosHelper.axiosDelete(url, {
    data,
    headers: paramHeaders.headers,
  });
};

// Suspend Business Email Account
export const suspendBusinessEmailAccountAPI = async (
  orderId: string,
  email: string,
) => {
  const url = `${serverUrl}/business-email/${orderId}/suspend-account`;
  const data = { email };
  return await axiosHelper.axiosPost(url, data, paramHeaders);
};

// Unsuspend Business Email Account
export const unsuspendBusinessEmailAccountAPI = async (
  orderId: string,
  email: string,
) => {
  const url = `${serverUrl}/business-email/${orderId}/unsuspend-account`;
  const data = { email };
  return await axiosHelper.axiosPost(url, data, paramHeaders);
};

// Get Business Email Account Details
export const getBusinessEmailAccountDetailsAPI = async (orderId: string) => {
  const url = `${serverUrl}/business-email/${orderId}/details`;
  return await axiosHelper.axiosGet(url, paramHeaders);
};

// Add Business Email Storage
export const addBusinessEmailStorageAPI = async (
  orderId: string,
  storageSize: number,
) => {
  const url = `${serverUrl}/business-email/${orderId}/add-storage`;
  const data = { storageSize };
  return await axiosHelper.axiosPost(url, data, paramHeaders);
};

// Delete Business Email Storage
export const deleteBusinessEmailStorageAPI = async (
  orderId: string,
  storageId: string,
) => {
  const url = `${serverUrl}/business-email/${orderId}/delete-storage`;
  const data = { storageId };
  return await axiosHelper.axiosDelete(url, {
    data,
    headers: paramHeaders.headers,
  });
};

// Get Business Email DNS Records
export const getBusinessEmailDNSRecordsAPI = async (orderId: string) => {
  const url = `${serverUrl}/business-email/${orderId}/dns-records`;
  return await axiosHelper.axiosGet(url, paramHeaders);
};

// Get Business Email Customer Pricing
export const getBusinessEmailCustomerPricingAPI = async (
  productKey: string,
) => {
  const url = `${serverUrl}/business-email/pricing/customer/${productKey}`;
  return await axiosHelper.axiosGet(url, paramHeaders);
};

// Get Business Email Reseller Pricing
export const getBusinessEmailResellerPricingAPI = async (
  productKey: string,
) => {
  const url = `${serverUrl}/business-email/pricing/reseller/${productKey}`;
  return await axiosHelper.axiosGet(url, paramHeaders);
};
